<template>
  <el-container>
    <el-row>
      <el-col :xs="2" :sm="2" :md="5" :lg="5" class="burger left_column">
        <el-aside style="width: initial; height: 100%;">
          <el-image :src="require('@/assets/logo.svg')" class="logo hidden-sm-and-down"></el-image>
          <Burger />
        </el-aside>
      </el-col>
      <el-col :xs="22" :sm="22" :md="19" :lg="19" class="right_column">
        <el-main>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-space>
                <h2>{{ classTitle }}</h2>
                <el-button type="text" @click="onLogout()">
                  <el-image :src="require('@/assets/exit.svg')" class="exit"></el-image>
                </el-button>
              </el-space>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col v-if="!videos.length" :span="24">
              <h3 class="doc">Вводный урок</h3>
              <el-divider></el-divider>
              <el-card shadow="hover">
                <div class="video">
                  <span v-html="intro"></span>
                </div>
                <div class="activation_block">
                  <span v-if="class_id === 1" class="price">Продажи закрыты</span>
                  <!--                  <span v-if="class_id === 2" class="price">Cтоимость курса: 19 900 рублей</span>-->
                  <!--                  <span v-if="class_id === 4" class="price">Cтоимость курса: 14 000 рублей</span>-->
                  <span class="discount">Бонус:</span>
                  <span v-if="class_id === 1" class="price">Полное руководство по Instagram, + TikTok, сотрудничество с брендами</span>
                  <!--                  <span v-if="class_id === 2" class="price">мастер класс "Молвинец. Алгоритм построения"</span>-->
                  <!--                  <span v-if="class_id === 4" class="price">трехчасовой вебинар по всем энергиям из курса "Погружение в энергии"</span>-->
<!--                  <div class="bottom">
                    <el-link :href="getPaymentUrl(class_id)" target="_blank" :underline="false">
                      <el-button type="text" class="button">
                        <el-image :src="require('@/assets/activation.svg')"></el-image>
                      </el-button>
                    </el-link>
                  </div>-->
                </div>
              </el-card>
            </el-col>
            <el-col v-for="video in videos" :key="video.id" :span="12" @click="getVideo(video.id)" :xs="24" :md="12">
              <el-card shadow="hover">
                <template #header>
                  <div class="card-header">
                    <h3>{{ video.video_title }}</h3>
                  </div>
                </template>
                <div class="video">
                  <el-image :src="getImgUrl(video.id)" class="scrin"></el-image>
                </div>
              </el-card>
            </el-col>
            <el-col v-if="videos.length">
              <h3 class="doc">файлы для скачивания</h3>
              <el-divider></el-divider>
              <el-space class="doc_space">
                <el-image :src="require('@/assets/doc.svg')"></el-image>
                <el-row>
                  <!--                  <el-col class="doc_button">-->
                  <!--                    <el-button type="text">-->
                  <!--                      <el-link :href="getGoogleDocsUrl(class_id, 'books')" target="_blank" icon="el-icon-document" type="info">{{ doc_title.books }}</el-link>-->
                  <!--                    </el-button>-->
                  <!--                  </el-col>-->
                  <el-col class="doc_button">
                    <el-button type="text">
                      <el-link :href="getGoogleDocsUrl(class_id, 'presentation_class')" target="_blank" icon="el-icon-document" type="info">{{ doc_title.presentation }}</el-link>
                    </el-button>
                  </el-col>
                  <!--                  <el-col class="doc_button" v-if="class_id === 2 || class_id === 4">-->
                  <!--                    <el-button type="text">-->
                  <!--                      <el-link :href="getGoogleDocsUrl(class_id, 'bonus')" target="_blank" icon="el-icon-document" type="info">{{ doc_title.bonus }}</el-link>-->
                  <!--                    </el-button>-->
                  <!--                  </el-col>-->
                  <el-col class="doc_button">
                    <el-button type="text">
                      <el-link :href="getGoogleDocsUrl(class_id, 'gift')" target="_blank" icon="el-icon-document" type="info">{{ doc_title.gift }}</el-link>
                    </el-button>
                  </el-col>
                </el-row>
              </el-space>
            </el-col>
          </el-row>
        </el-main>
        <el-footer>
          <span>Полититка конфедициальности</span><br>
          <span>let.tobe.happy@gmail.com</span>
        </el-footer>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>

import Burger from '../components/Burger.vue';
import 'element-plus/lib/theme-chalk/display.css';
import axiosInstance from '../services/AxiosTokenInstance';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  LOGOUT_ACTION,
  GET_USER_TOKEN_GETTER,
  LOADING_SPINNER_SHOW_MUTATION,
} from '../store/storeconstants';

export default {
  data() {
    return {
      videos: [],
      classTitle: '',
      intro: '',
      class_id: 0,
      doc_title: {
        books: '',
        presentation: '',
        bonus: '',
        gift: ''
      }
    };
  },
  computed: {
    ...mapGetters('auth', {
      token: GET_USER_TOKEN_GETTER,
    }),
  },
  components: {
    Burger,
  },
  mounted() {
    this.showLoading(true);
    axiosInstance
        .get(`https://betterlife-school.ru/app/api/me/class/${this.$route.params.id}`)
        .then((response) => {
          this.class_id = Number(this.$route.params.id);
          this.formatVideos(response.data, this.$route.params.id);
          this.showLoading(false);
        })
        .catch(() => {
          this.showLoading(false);
        });
    axiosInstance
        .get(`https://betterlife-school.ru/app/api/class/video/${this.$route.params.id}`)
        .then((response) => {
          this.class_id = Number(this.$route.params.id);
          this.classTitle = response.data.classes_title;
          this.intro = response.data.link;
          this.showLoading(false);
        })
        .catch(() => {
          this.showLoading(false);
        });
  },
  methods: {
    ...mapActions('auth', {
      logout: LOGOUT_ACTION,
    }),
    ...mapMutations({
      showLoading: LOADING_SPINNER_SHOW_MUTATION,
    }),
    formatVideos(videos, id) {
      this.classTitle = videos[id].classes_title;
      this.class_id = Number(id);
      for (let key in videos) {
        this.videos.push({...videos[key]});
      }
    },
    onLogout() {
      this.logout();
      this.$router.replace('/login');
    },
    getVideo(id) {
      this.$router.push({ path: `/class/video/${id}` })
    },
    getImgUrl(pic) {
      const img = `image_class${pic}.jpg`
      return require('../assets/images/' + img);
    },
    getPaymentUrl(id) {
      const config = require('../config/index.js');
      return config.robokassa_class[id];
    },
    getGoogleDocsUrl(id, type) {
      const config = require('../config/index.js');
      // if(id == 1 || id ==2 || id == 4) this.doc_title.books = 'Книга "22 энергии"';
      if(id == 1) this.doc_title.presentation = 'Самоценность презентация';
      // if(id == 2) this.doc_title.presentation = 'Для продвинутых презентация';
      // if(id == 4) this.doc_title.presentation = 'Для проводников презентация';
      // if(id == 2) this.doc_title.bonus = 'Бонус Молвинец презентация';
      // if(id == 4) this.doc_title.bonus = '4D восприятие по энергиям для проводников';
      this.doc_title.gift = 'Боль блогера. Руководство по продвижению'
      return config.googleDocs[type][id];
    }
  },
};
</script>

<style>
@media only screen and (min-width: 992px) {
  .bm-menu {
    min-width: 20%;
    margin-top: 13%;
  }
  .bm-burger-button {
    display: none;
  }
  .bm-cross-button {
    display: none;
  }
}
.courses_card .el-card {
  background-color: #F4F4F6;
  border-radius: 15px;
}
</style>

<style scoped>
.logo {
  position: fixed;
  padding: 10px;
  margin-bottom: 20px;
  width: 15%;
  left: 2%;
  top: 5%;
}
.el-main {
  height: 100%;
  padding-bottom: 100px;
}
.el-space {
  display: flex;
  justify-content: space-between;
}
.el-container {
  background: #ffffff;
}
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
body > .el-container {
  margin-bottom: 40px;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}
.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.el-divider {
  margin-top: 0;
}
.activation_block {
  padding-top: 30px;
  text-align: center;
  display: grid;
  line-height: 2;
}
.price {
  font-family: 'Montserrat', sans-serif!important;
  text-transform: uppercase;
  font-weight: 300!important;
  font-style: normal!important;
  font-size: 16px!important;
}
.discount {
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
  font-size: 16px!important;
  color: #5E597B;
  margin-top: 16px;
}
.doc_button {
  margin: 0 0 0 5px!important;
}
.doc_space {
  justify-content: start;
}
</style>
